import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../Theme";
import Header from "./Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { getSessionData } from "./helpers/SessionHelper";


const Applicant = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [mockData, setMockData] = useState([]);

    useEffect(() => {
        try {
            const token = getSessionData('token');
            axios.get('http://127.0.0.1:8000/api/v1/applicants', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((res) => {
                console.log(res.data)
                setMockData(res.data.data);
            })
        } catch (error) {
            console.error(error);
        }
    }, []);

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "email",
            headerName: "Email Address",
            flex: 1
        },
        {
            field: "jobTitle",
            headerName: "Job Title",
            flex: 1,
        },
        {
            field: "salary",
            headerName: "Salary",
            flex: 1,
        },
        {
            field: "resume",
            headerName: "Resume",
            flex: 1,
        },
        {
            field: "coverLetter",
            headerName: "Cover Letter",
            flex: 1,
        }
    ];


    return (
        <Box m="20px">
            <Header title="Job Applicants" subtitle="View Job Applicants" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                {/* <DataGrid  rows={mockData} columns={columns} components={{ Toolbar: GridToolbar }} /> */}
                <DataGrid
                    checkboxSelection
                    rows={mockData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    )
}

export default Applicant;