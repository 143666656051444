import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../Theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import Header from "./Header";
import { useState, useEffect } from "react";
import axios from "axios";

const Job = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [mockData, setMockData] = useState([])

    useEffect(() => {
        try {
            axios.get('http://127.0.0.1:8000/api/v1/jobs')
                .then((res) => {
                    console.log(res.data)
                    setMockData(res.data.data);
                })
        } catch (error) {
            console.error(error);
        }
    }, [])

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1
        },
        {
            field: "department",
            headerName: "Department",
            flex: 1,
        },
        {
            field: "employmentType",
            headerName: "Employment Type",
            flex: 1,
        },
        {
            field: "location",
            headerName: "Location",
            flex: 1,
        },
        {
            field: "is_active",
            headerName: "Status",
            flex: 1,
            renderCell: ({ row: { is_active } }) => {
                return (
                    <Box
                        width="60%"
                        m="1 auto"
                        marginTop="0.25rem"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            is_active === 1
                                ? colors.blueAccent[600]
                                : is_active === 0
                                    ? colors.blueAccent[700]
                                    : colors.blueAccent[700]
                        }
                        borderRadius="4px"
                    >
                        {is_active === 0 && <WorkOffOutlinedIcon />}
                        {is_active === 1 && <CasesOutlinedIcon />}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                            {is_active === 1 ? "Active" : "Inactive"}
                        </Typography>
                    </Box>
                );
            },
        },
    ];




    return (
        <Box m="20px">
            <Header title="Jobs" subtitle="Manage Jobs" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid checkboxSelection rows={mockData} columns={columns} />
            </Box>
        </Box>
    )
}

export default Job;