import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../Theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import Header from "./Header";
import { useState, useEffect } from "react";
import axios from "axios";

const Blog = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [mockData, setMockData] = useState([])


    useEffect(() => {
        try {
            axios.get('http://127.0.0.1:8000/api/v1/blogs')
                .then((res) => {
                    console.log(res.data)
                    setMockData(res.data.data);
                })
        } catch (error) {
            console.error(error);
        }
    }, [])

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "authorName",
            headerName: "Author",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "topic",
            headerName: "Topic",
            flex: 1
        },
        {
            field: "body",
            headerName: "Body",
            flex: 1,
        },
        {
            field: "category",
            headerName: "Category",
            flex: 1,
        },
        {
            field: "isActive",
            headerName: "Status",
            flex: 1,
            renderCell: ({ row: { isActive } }) => {
                return (
                    <Box
                        width="60%"
                        m="1 auto"
                        marginTop="0.25rem"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            isActive === true
                                ? colors.blueAccent[600]
                                : isActive === false
                                    ? colors.blueAccent[700]
                                    : colors.blueAccent[700]
                        }
                        borderRadius="4px"
                    >
                        {isActive === false && <AdminPanelSettingsOutlinedIcon />}
                        {isActive === true && <CloudDoneOutlinedIcon />}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                            {isActive === false ? "Not Uploaded" : "Uploaded"}
                        </Typography>
                    </Box>
                );
            },
        },
    ];



    return (
        <Box m="20px">
            <Header title="Blog Posts" subtitle="Manage Blog Posts" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid checkboxSelection rows={mockData} columns={columns} />
            </Box>
        </Box>
    )
}

export default Blog;