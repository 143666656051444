import React from 'react';
import { ColorModeContext, useMode } from '../Theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
// import styles from "./css/navlogin.css";

const Dashboard = () => {
    const [theme, colorMode] = useMode();

    return (
        <div>

        </div>
    )
}

export default Dashboard