import { Box, Button, TextField, Grid } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";
import { useState } from "react";
import axios from "axios";
import { getSessionData } from "./helpers/SessionHelper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const Newjob = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [depart, setDepart] = useState('');
    const [job, setJob] = useState('');
    const [locate, setLocate] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const user = getSessionData('user');
    const userId = user.id;
    const token = getSessionData('token');

    const handleSel = (event) => {
        console.log(event.target.value)
        setDepart(event.target.value)
    }
    const handleSet = (event) => {
        console.log(event.target.value)
        setJob(event.target.value)
    }
    const handleSec = (event) => {
        console.log(event.target.value)
        setLocate(event.target.value)
    }

    async function handleFormSubmit(e) {
        let url = `${process.env.REACT_APP_URL_NAME}/jobs`;
        // title, description, salary, depart, job, locate
        // 'title' => 'required|string',
        //     'description' => 'required|string',
        //     'department' => 'required|string',
        //     'employment_type' => 'required|string',
        //     'location' => 'required|string',
        //     'salary'
        const formData = new FormData();
        formData.append("title", e.title)
        formData.append("description", e.description)
        formData.append("department", depart)
        formData.append("employment_type", job)
        formData.append("salary", e.salary)
        formData.append("location", locate)

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setErrorMessage("")
            console.log(response.data.message);
            alert("cool")
            window.location.reload();
        }).catch(err => {
            // const rat = err.response.data.message;
            console.error(err.response.data.message);
            // alert(rat);
            setErrorMessage(err.response.data.message)
        });
    };

    return (
        <Box m="20px">
            <Header title="ADD NEW JOB" subtitle="Create a new job opening" />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Job Title"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.title}
                                name="title"
                                error={!!touched.title && !!errors.title}
                                helperText={touched.title && errors.title}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Job Description"
                                multiline
                                rows={5}
                                rowsmax={10}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.description}
                                name="description"
                                error={!!touched.description && !!errors.description}
                                helperText={touched.description && errors.description}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Salary"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.salary}
                                name="salary"
                                error={!!touched.salary && !!errors.salary}
                                helperText={touched.salary && errors.salary}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">Department</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={depart}
                                    label="Department"
                                    onChange={handleSel}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'Engineering'}>Engineering</MenuItem>
                                    <MenuItem value={'Public Relations'}>Public Relations</MenuItem>
                                    <MenuItem value={'Administration'}>Administration</MenuItem>
                                    <MenuItem value={'Marketing'}>Marketing</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">Job Type</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={job}
                                    label="Job Type"
                                    onChange={handleSet}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'Full-time Physical'}>Full-time Physical</MenuItem>
                                    <MenuItem value={'Full-time Remote'}>Full-time Remote</MenuItem>
                                    <MenuItem value={'Full-time Hybrid'}>Full-time Hybrid</MenuItem>
                                    <MenuItem value={'Part-time Physical'}>Part-time Physical</MenuItem>
                                    <MenuItem value={'Part-time Remote'}>Part-time Remote</MenuItem>
                                    <MenuItem value={'Part-time Hybrid'}>Part-time Hybrid</MenuItem>
                                    <MenuItem value={'Internship'}>Internship</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">Location</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={locate}
                                    label="Job Location"
                                    onChange={handleSec}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'Remote'}>Remote</MenuItem>
                                    <MenuItem value={'Lagos, Nigeria'}>Lagos, Nigeria</MenuItem>
                                    <MenuItem value={'Abuja, Nigeria'}>Abuja, Nigeria</MenuItem>
                                </Select>
                            </FormControl>


                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Add Job
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box >
    );
};


const checkoutSchema = yup.object().shape({
    title: yup.string().required("required"),
    description: yup.string().required("required"),
    salary: yup.string(),
});

const initialValues = {
    title: "",
    description: "",
    salary: "",
};

export default Newjob;