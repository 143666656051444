import { Box, Button, TextField, Grid } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";
import { useState } from "react";
import axios from "axios";
import { getSessionData } from "./helpers/SessionHelper";
import FileUploader from "./FileUploader/FileUploader";




const Newblog = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [level, setLevel] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const user = getSessionData('user');
    const userId = user.id;
    const token = getSessionData('token');
    const [imaged, setImaged] = useState(0);


    const onSelectFileHandler = (e) => {
        // console.log(user.name);
        // console.log(user.level);
        if (e.target.files[0]) {
            setImaged(e.target.files[0]);
        }
        // console.log(imaged);
    }

    const onDeleteFileHandler = () => {
        setImaged('');
    }

    async function handleFormSubmit(e) {
        let url = `${process.env.REACT_APP_URL_NAME}/blogs`;
        const formData = new FormData();
        formData.append("user_id", userId)
        formData.append("topic", e.topic)
        formData.append("body", e.body)
        formData.append("category", e.category)
        formData.append("image", imaged)



        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setErrorMessage("")
            console.log(response.data.message);
            alert("cool")
            window.location.reload();
        }).catch(err => {
            // const rat = err.response.data.message;
            console.error(err.response.data.message);
            // alert(rat);
            setErrorMessage(err.response.data.message)
        });
    };

    return (
        <Box m="20px">
            <Header title="ADD NEW BLOG" subtitle="Create a new blog post" />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Topic"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.topic}
                                name="topic"
                                error={!!touched.topic && !!errors.topic}
                                helperText={touched.topic && errors.topic}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Category"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.category}
                                name="category"
                                error={!!touched.category && !!errors.category}
                                helperText={touched.category && errors.category}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Body"
                                multiline
                                rows={5}
                                rowsmax={10}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.body}
                                name="body"
                                error={!!touched.body && !!errors.body}
                                helperText={touched.body && errors.body}
                                sx={{ gridColumn: "span 4" }}
                            />

                            <Grid item xs={6} p={2}>
                                <label>Blog Image</label>
                                <FileUploader onSelectFile={onSelectFileHandler}
                                    onDeleteFile={onDeleteFileHandler} />
                            </Grid>
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Add Blog Post
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};


const checkoutSchema = yup.object().shape({
    topic: yup.string().required("required"),
    body: yup.string().required("required"),
    category: yup.string().required("required"),
});

const initialValues = {
    topic: "",
    body: "",
    category: "",
};

export default Newblog;