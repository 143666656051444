import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../Theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "./Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { getSessionData } from "./helpers/SessionHelper";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [mockData, setMockData] = useState([])

    useEffect(() => {
        try {
            const token = getSessionData('token');
            axios.get('http://127.0.0.1:8000/api/v1/users', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((res) => {
                console.log(res.data)
                setMockData(res.data.data);
            })
        } catch (error) {
            console.error(error);
        }
    }, [])


    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1
        },
        {
            field: "role",
            headerName: "Role",
            flex: 1,
        },
        {
            field: "phoneNumber",
            headerName: "Telephone",
            flex: 1,
        },
        {
            field: "isActive",
            headerName: "Admin Active",
            flex: 1,
        },
        {
            field: "level",
            headerName: "Access Level",
            flex: 1,
            renderCell: ({ row: { level } }) => {
                return (
                    <Box
                        width="80%"
                        m="1 auto"
                        marginTop="0.25rem"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            level === "Super Admin"
                                ? colors.blueAccent[600]
                                : level === "Sub Admin"
                                    ? colors.blueAccent[700]
                                    : colors.blueAccent[700]
                        }
                        borderRadius="4px"
                    >
                        {level === "Super Admin" && <AdminPanelSettingsOutlinedIcon />}
                        {level === "Sub Admin" && <SecurityOutlinedIcon />}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                            {level}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    return (
        <Box m="20px">
            <Header title="Admins" subtitle="Manage Admins" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    checkboxSelection
                    rows={mockData}
                    columns={columns}
                    slots={{ Toolbar: GridToolbar, }}
                />
            </Box>
        </Box>
    );
};

export default Team;





