import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./Theme";
import Sidebar from "./Components/Sidebar";
import Topbar from "./Components/Topbar";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/Login";
import ProtectedRoute from "./Components/ProtectedRoute";
import Team from "./Components/Team";
import Blog from "./Components/Blog";
import Job from "./Components/Job";
import Applicant from "./Components/Applicant";
import Enquiry from "./Components/Enquiry";
import Admin from "./Components/Admin";
import Newblog from "./Components/Newblog";
import Newjob from "./Components/Newjob";



function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar} />
              <Routes>
                //auth & dashboard
                <Route path='/admin/login' element={<Login />} />
                <Route path="/" element={<ProtectedRoute />}>
                  <Route index element={<Dashboard />} />
                </Route>

                //admin
                <Route path="/team" element={<ProtectedRoute />}>
                  <Route path="/team" element={<Team />} />
                </Route>
                <Route path="/admins/new" element={<ProtectedRoute />}>
                  <Route path="/admins/new" element={<Admin />} />
                </Route>

                //blogs
                <Route path="/blogs" element={<ProtectedRoute />}>
                  <Route path="/blogs" element={<Blog />} />
                </Route>
                <Route path="/blogs/new" element={<ProtectedRoute />}>
                  <Route path="/blogs/new" element={<Newblog />} />
                </Route>

                //jobs & applicants
                <Route path="/jobs" element={<ProtectedRoute />}>
                  <Route path="/jobs" element={<Job />} />
                </Route>
                <Route path="/jobs/applicants" element={<ProtectedRoute />}>
                  <Route path="/jobs/applicants" element={<Applicant />} />
                </Route>
                <Route path="/jobs/new" element={<ProtectedRoute />}>
                  <Route path="/jobs/new" element={<Newjob />} />
                </Route>



                //Enquiries
                <Route path="/enquiries" element={<ProtectedRoute />}>
                  <Route path="/enquiries" element={<Enquiry />} />
                </Route>






                {/*
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} /> */}
              </Routes>
            </main>
          </div>
        </Router>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;