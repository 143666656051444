import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from "react";
import axios from "axios";
import { getSessionData } from "./helpers/SessionHelper";

const Admin = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [level, setLevel] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const token = getSessionData('token');

    const handleSel = (event) => {
        console.log(event.target.value)
        setLevel(event.target.value)
    }
    async function handleFormSubmit(e) {
        // e.preventDefault()
        let url = `${process.env.REACT_APP_URL_NAME}/signup`;

        const formData = new FormData();
        formData.append("name", e.name)
        formData.append("email", e.email)
        formData.append("level", level)

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setErrorMessage("")
            console.log(response.data.message);
            alert("cool")
            window.location.reload();
        }).catch(err => {
            // const rat = err.response.data.message;
            console.error(err.response.data.message);
            // alert(rat);
            setErrorMessage(err.response.data.message)
        });
        // console.log(values);
    };

    return (
        <Box m="20px">
            <Header title="CREATE SUB-ADMIN" subtitle="Create a New Sub-Admin Profile" />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">Level</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={level}
                                    label="Age"
                                    onChange={handleSel}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'Sub-Admin'}>Sub-Admin</MenuItem>
                                    <MenuItem value={'Financial Admin'}>Financial Admin</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Create New Admin
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};


const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
});
const initialValues = {
    name: "",
    email: "",
};

export default Admin;